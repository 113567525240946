// Defining application SASS variables in stand-alone file,
// so we can use them in bootstrap-loader and CSS Modules via sass-resources-loader
$body-bg: #FFFFFF;                          // background w/ character
$navbar-default-bg: #FFFFE0;                // fancy yellow navbar
$font-size-base: 15px;                      // make it bigger!
$font-family-sans-serif: 'SourceSansPro';  // apply custom font

// It will be used in SASS components imported as CSS Modules
$comment-author-color: blue;
$comment-text-color: purple;

.formContainer {
  margin-top: 100px;
}

h1, h2, h3 {
  margin-bottom: 0 !important;
}

.leftViewContainer {
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px;
  min-width: 375px;
}

.rightViewContainer {
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px;
  border-radius: 100px 0 0 0;
  background: #FAFAFA;
  height: calc(100vh - 198px);
  width: 100%;
  min-width: 375px;
}

.leaveBookingCard {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.102);
}