// Defining application SASS variables in stand-alone file,
// so we can use them in bootstrap-loader and CSS Modules via sass-resources-loader
$body-bg: #FFFFFF;                          // background w/ character
$navbar-default-bg: #FFFFE0;                // fancy yellow navbar
$font-size-base: 15px;                      // make it bigger!
$font-family-sans-serif: 'SourceSansPro';  // apply custom font

// It will be used in SASS components imported as CSS Modules
$comment-author-color: blue;
$comment-text-color: purple;

.loginForm {
  padding: 75px;
}

.formContainer {
  width: 540px;
  margin-top: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 30px;
}

.loginFormForgot {
  float: right;
}

.formSubmit {
  margin-top: 80px;
  text-align: center;
}

.signupFormButton {
  width: 100%;
}