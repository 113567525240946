// Defining application SASS variables in stand-alone file,
// so we can use them in bootstrap-loader and CSS Modules via sass-resources-loader
$body-bg: #FFFFFF;                          // background w/ character
$navbar-default-bg: #FFFFE0;                // fancy yellow navbar
$font-size-base: 15px;                      // make it bigger!
$font-family-sans-serif: 'SourceSansPro';  // apply custom font

// It will be used in SASS components imported as CSS Modules
$comment-author-color: blue;
$comment-text-color: purple;

.headerContainer {
  text-align: center;
  margin-top: 50px;
}

.mainContainer {
  text-align: center;
  ul {
    list-style: none;
  }
}

.brandContainer {
  padding: 20px;
  margin-bottom: 70px;
  text-align: center;
}

.mainArea {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  min-height: 360px;
  //height: calc(100vh - 74px);
  //overflow-y: auto;
  //overflow-x: hidden;
}

.sideMenu {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  margin-right: -100px;
}
